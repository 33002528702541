<template>
   <div class="modal-favorites">
      <div :data-state="warning" class="modal-favorites__inner">
         <product-card v-for="product in products" :key="product.id" :product="product" />
      </div>

      <favorites-footer />
   </div>
</template>

<script>
   import ProductCard from './modules/ProductCard';
   import FavoritesFooter from './modules/FavoritesFooter';
   import { mapState, mapGetters } from 'vuex';

   export default {
      name: 'ModalFavorites',

      components: {
         ProductCard,
         FavoritesFooter
      },

      emits: {
         'update:title': (value) => typeof value === 'string'
      },

      beforeMount() {
         this.$emit('update:title', 'Избранное');
      },

      computed: {
         ...mapState('favorites', ['products']),
         ...mapGetters('favorites', ['productsCount']),

         warning() {
            return this.productsCount ? null : 'Избранные товары отсутствуют';
         }
      }
   };
</script>
